import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import {
  Button,
  ColorBatch,
  Icon,
  showApiError,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  useUrlParams,
  HotelGallery,
  RatingReviewStars,
} from 'components';
import { HOTEL_ENTERTAINMENT_MAP } from 'enums';
import { copyToClipboard } from 'utils';
import { getHotelById } from 'services';
import Overview from './Overview';
import Availability from './Availability';
import { detailsContainer, tooltipContent, tabsStyle } from './styles';

const HotelDetails = ({ history }) => {
  const { getText } = useTranslations();
  const { queryParams, setQueryParams } = useUrlParams();
  const [hotelDetails, setHotelDetails] = useState({});
  const params = useParams();
  const hotelPropertyThemes = history.location?.state?.propertyThemes || hotelDetails?.propertyThemes;
  const hotelId = params?.hotelId ?? history.location?.state?.id;
  const fromBooking = history.location?.state?.fromBooking;
  const searchId = history.location?.state?.searchId;
  const provider = queryParams?.provider ?? history.location?.state?.providerName;
  const hotelUrl = window.location.href;
  const hideAvailability = queryParams?.hide;

  useEffect(() => {
    !isNil(history.location?.state?.filters) && setQueryParams(history.location?.state?.filters);
    fetchHotelDetails();
  }, []);

  const fetchHotelDetails = async () => {
    const [res, err] = await getHotelById(hotelId, { searchId, provider });
    err ? showApiError(err) : setHotelDetails(res);
  };

  return (
    <div css={detailsContainer}>
      <HotelGallery hotelImages={hotelDetails?.hotelImages} className="gallery" />
      <div className="container">
        <div>
          {isEmpty(hotelDetails) ? (
            <Skeleton width={400} height={25} className="margin" />
          ) : (
            <RatingReviewStars hotel={hotelDetails} />
          )}
          {isNil(hotelDetails?.name) ? (
            <Skeleton width={500} height={24} className="title" />
          ) : (
            <h4 className="title">{hotelDetails?.name}</h4>
          )}
          {isNil(hotelDetails?.address) ? (
            <Skeleton width={700} height={17} />
          ) : (
            <div className="location">
              {hotelDetails?.address?.address !== '' && <span>{`${hotelDetails?.address?.address}`}</span>}
              {hotelDetails?.address?.cityName !== '' && <span>{`, ${hotelDetails?.address?.cityName}`}</span>}
              {hotelDetails?.address?.stateName !== '' && <span>{`, ${hotelDetails?.address?.stateName}`}</span>}
              {hotelDetails?.address?.countryName !== '' && <span>{`, ${hotelDetails?.address?.countryName} `}</span>}
            </div>
          )}
        </div>
        <div className="entertainment-container">
          {hotelPropertyThemes && (
            <div>
              {hotelPropertyThemes?.slice(0, 2)?.map((el, ind) => (
                <ColorBatch
                  key={ind}
                  withoutStartCase
                  type={HOTEL_ENTERTAINMENT_MAP[el?.id]?.color}
                  className="entertainment-badge">
                  <div className="badge-container">
                    <Icon size={20} material iconName={HOTEL_ENTERTAINMENT_MAP[el?.id]?.icon} margin="0 4px 0 0" />{' '}
                    <p>{getText(HOTEL_ENTERTAINMENT_MAP[el?.id]?.text)}</p>
                  </div>
                </ColorBatch>
              ))}
            </div>
          )}
          <Button type="link" onClick={() => copyToClipboard(hotelUrl, getText('successfullyCopiedHotel'))}>
            <Tooltip content={getText('share')} noArrow className="tooltip-container" contentClassName={tooltipContent}>
              <Icon material iconName="upload" />
            </Tooltip>
          </Button>
        </div>
      </div>
      <Tabs
        noAnimation
        css={tabsStyle}
        startingRoute={`/hotels/${hotelId}`}
        sharedQueryParams={['adults', 'checkIn', 'checkOut', 'children', 'rooms', 'searchId']}>
        <Tab url="/overview" label={getText('overview')} component={() => <Overview hotelDetails={hotelDetails} />} />
        {!fromBooking && !hideAvailability && (
          <Tab
            url="/availability"
            label={getText('availability')}
            component={() => <Availability hotelDetails={hotelDetails} />}
          />
        )}
      </Tabs>
    </div>
  );
};

export default HotelDetails;
