import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize, isNil } from 'lodash-es';
import { add, mul } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Icon, PriceLabel, showApiError, Skeleton } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { getConfig } from 'services';
import { bestDealContainer } from './styles';

const BestDealCart = (props) => {
  const { bestDealRoom, onTabChange, className } = props;
  const { getText } = useTranslations();
  const [config, setConfig] = useState({});
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfig('CashForCoinExchangeRate');
    err ? showApiError(err) : setConfig(res ? JSON.parse(res.value) : false);
  };

  return (
    <div css={bestDealContainer(bestDealRoom?.priceDetails?.cryptoPrice > 0)} {...(className && { className })}>
      <div className="header">
        <div>
          <h5 className="reasons-title">{getText('bestDeal')}</h5>
          {isNil(bestDealRoom) ? (
            <Skeleton width={300} height={37} />
          ) : (
            <div className="price">
              {bestDealRoom?.priceDetails?.price > 0 && (
                <h4 className="discounted-price">
                  <PriceLabel
                    fiat={Number(bestDealRoom?.priceDetails?.price)}
                    crypto={bestDealRoom?.priceDetails?.cryptoPrice}
                    fiatOriginCurrency={DEFAULT_CURRENCY.code}
                    fiatDesiredCurrency={selectedCurrency?.code}
                    fiatOnly={false}
                  />
                </h4>
              )}
              <p className="original-price">
                <PriceLabel
                  fiat={Number(
                    add(
                      bestDealRoom?.priceDetails?.cryptoPrice
                        ? mul(bestDealRoom?.priceDetails?.cryptoPrice, config)
                        : 0,
                      add(bestDealRoom?.priceDetails?.price ?? 0, bestDealRoom?.priceDetails?.savingsAmount ?? 0),
                    ),
                  )}
                  fiatOriginCurrency={DEFAULT_CURRENCY.code}
                  fiatDesiredCurrency={selectedCurrency?.code}
                  fiatOnly={false}
                />
              </p>
            </div>
          )}
        </div>
        {isNil(bestDealRoom) ? (
          <Skeleton width={100} height={41} className="button" />
        ) : (
          <Button onClick={() => onTabChange('Availability')} className="button" small>
            {getText('viewDeals')}
          </Button>
        )}
      </div>
      <div className="reasons-container">
        {isNil(bestDealRoom) ? (
          <Skeleton width={48} height={48} className="icon-currency" />
        ) : (
          <Icon material iconName="currency_exchange" className="icon-currency" />
        )}
        {isNil(bestDealRoom) ? (
          <Skeleton width={200} height={60} className="icon-currency" />
        ) : (
          <div>
            <h5 className="title">{getText('cancellationPolicy')}</h5>
            <p className="subtitle">{capitalize(getText(bestDealRoom?.cancellationPolicy))}</p>
          </div>
        )}
      </div>
    </div>
  );
};

BestDealCart.propTypes = {
  bestDealRoom: PropTypes.object,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
};

export default BestDealCart;
