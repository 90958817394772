import { useState } from 'react';
import { mul, div, sub } from 'exact-math';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Dropdown, Icon, showApiError, PaymentMethodsView } from 'components';
import { USER_STATUS, WALLET_TYPE } from 'enums';
import { getAccountWallets, getConfig } from 'services';
import { useUserStore } from 'stores';
import { fadeInAnimation } from 'styles';
import { accountPaymentMethodsViewContainer, errorContent } from './styles';
import { useEffect } from 'react';

const AccountPaymentMethodsView = ({
  feature,
  hasError,
  paymentData,
  onSetHasError,
  onSetWallets,
  onSetAccountAndPaymentMethod,
  onScrollToError,
}) => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const [config, setConfig] = useState({});
  const activeAccounts = user?.accounts?.filter((el) => el.status === USER_STATUS.Active);
  const cryptoPrice =
    !isNil(paymentData?.values?.paymentMethod) &&
    !isNil(paymentData?.itemPrice) &&
    div(
      mul(
        div(paymentData?.values?.paymentMethod?.coinPercent ?? 0, 100),
        paymentData?.itemPrice === '' ? 0 : paymentData?.itemPrice,
      ),
      config ?? 0,
    )?.toFixed(4);

  const fiatPrice =
    !isNil(paymentData?.values?.paymentMethod) &&
    !isNil(paymentData?.itemPrice) &&
    mul(
      div(paymentData?.values?.paymentMethod?.cashPercent ?? 0, 100),
      paymentData?.itemPrice === '' ? 0 : paymentData?.itemPrice,
    )?.toFixed(2);

  const hasPriceError =
    cryptoPrice >
      sub(
        paymentData?.wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())?.balance ??
          0,
        paymentData?.wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())
          ?.blockedBalance ?? 0,
      ) ||
    fiatPrice >
      sub(
        paymentData?.wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())?.balance ??
          0,
        paymentData?.wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())
          ?.blockedBalance ?? 0,
      );

  useEffect(() => {
    onScrollToError('#priceError');
    return onSetHasError((prev) => ({ ...prev, priceError: hasPriceError }));
  }, [hasPriceError]);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfig('CashForCoinExchangeRate');
    err ? showApiError(err) : setConfig(res ? JSON.parse(res.value) : false);
  };

  const fetchWalletsByAccountId = async (accountId) => {
    const [res, err] = await getAccountWallets(accountId);
    err ? showApiError(err) : onSetWallets(res);
  };

  const handleSelectAccount = (val) => {
    fetchWalletsByAccountId(val.id);
    onSetAccountAndPaymentMethod((prev) => ({ ...prev, senderAccountId: val.id }));
    onSetHasError((prev) => ({ ...prev, senderAccountId: false }));
  };

  const handleSetPaymentMethod = (val) => {
    onSetAccountAndPaymentMethod((prev) => ({ ...prev, paymentMethodName: val?.name, paymentMethod: val }));
    onSetHasError((prev) => ({ ...prev, paymentMethod: false }));
  };

  return (
    <div css={accountPaymentMethodsViewContainer(hasError)} id="#accountDropdown">
      {hasPriceError && (
        <div css={[errorContent, fadeInAnimation()]}>
          <Icon material iconName="error" className="error-icon" size={16} />
          <span>{getText('notEnoughBalance')}</span>
        </div>
      )}

      <h3 className="title">{getText('selectAccount')}</h3>

      <Dropdown
        noClear
        withSearch
        label={getText('selectAccount')}
        uniqueKey="id"
        displayKey="displayName"
        className="accounts-dropdown"
        options={activeAccounts}
        onChange={handleSelectAccount}
      />

      {hasError?.senderAccountId && (
        <div className="error-container">
          <Icon material iconName="error" className="error-icon" size={14} color="error" />
          <p className="error-text">{getText('pleaseSelectAccount')}</p>
        </div>
      )}

      {activeAccounts?.length === 0 && (
        <div className="error-container">
          <Icon material iconName="error" className="error-icon" size={14} color="error" />
          <p className="error-text">{getText('doNotHaveAccounts', { accountStatus: USER_STATUS.Active })}</p>
        </div>
      )}

      <h3 className="title">{getText('paymentMethod')}</h3>

      <PaymentMethodsView
        feature={feature}
        values={paymentData?.values}
        hasError={hasError}
        onSetPaymentMethod={handleSetPaymentMethod}
      />

      {hasError?.paymentMethod && (
        <div className="error-container">
          <Icon material iconName="error" className="error-icon" size={14} color="error" />
          <p className="error-text">{getText('pleaseSelectPaymentMethod')}</p>
        </div>
      )}
    </div>
  );
};

export default AccountPaymentMethodsView;
