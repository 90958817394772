import { useState, useEffect } from 'react';
import { mul, div } from 'exact-math';
import { isNil, startCase } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Icon, Button, PriceLabel, showApiError, WalletCart } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { getConfig } from 'services';
import { walletContainer } from './styles';

const BuyWalletsView = ({ wallets, buyItemPrice, paymentMethod, withoutPayPrice, className }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const [config, setConfig] = useState({});
  const oneLifeUrlWallets = `${applicationUrls.lifeUrl}/wallet`;
  const cryptoPrice =
    !isNil(paymentMethod) &&
    !isNil(buyItemPrice) &&
    div(mul(div(paymentMethod?.coinPercent ?? 0, 100), buyItemPrice === '' ? 0 : buyItemPrice), config ?? 0)?.toFixed(
      4,
    );
  const fiatPrice =
    !isNil(paymentMethod) &&
    !isNil(buyItemPrice) &&
    mul(div(paymentMethod?.cashPercent ?? 0, 100), buyItemPrice === '' ? 0 : buyItemPrice)?.toFixed(2);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfig('CashForCoinExchangeRate');
    err ? showApiError(err) : setConfig(res ? JSON.parse(res.value) : false);
  };

  const handleGoToOneLife = () => {
    window.location.href = oneLifeUrlWallets;
  };

  return (
    <div css={walletContainer} {...(className && { className })}>
      {paymentMethod && buyItemPrice !== 0 && !withoutPayPrice && (
        <div className="calculated-prices-container">
          <h4 className="title">{getText('youNeedToPay')}</h4>
          <div className="calculated-prices">
            <div>
              <p className="price">{convertedToLocalCurrencyPrice(fiatPrice)}</p>
              <p className="wallet">{`${paymentMethod?.cashPercent}% ${getText('fiatWallet')}`}</p>
            </div>
            <div className="plus">+</div>
            <div>
              <p className="price">{cryptoPrice && `${cryptoPrice} ${startCase(getText('cryptoCurrency'))}`}</p>
              <p className="wallet">{`${paymentMethod?.coinPercent}% ${getText('cryptoWallet')}`}</p>
            </div>
          </div>
        </div>
      )}

      {paymentMethod && <h4 className="title">{getText('yourBalance')}</h4>}
      <div className="wallet-cart-container">
        {wallets?.map((el, ind) => (
          <WalletCart key={ind} wallet={el} className="wallet-cart" />
        ))}
      </div>
      <Button type="link" onClick={handleGoToOneLife} className="button-life">
        <p className="button-text">{getText('goToMyWalletsOneLife')}</p>
        <Icon material iconName="ungroup" size={24} />
      </Button>
    </div>
  );
};

export default BuyWalletsView;
