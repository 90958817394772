import { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { useUrlParams, ColorBatch, PriceLabel, Tooltip } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { starsBadgeInfo } from './config';
import { modalContainer, tooltipContainer, tooltipContent } from './styles';

const DISPLAY_DATE_FORMAT = 'ddd,MMM D';

const PriceStarsTooltipContent = forwardRef((props, ref) => {
  const { destination, starsData, onSelectHotelStars, selectedHotelStars } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const { queryParams } = useUrlParams();
  const { checkIn, checkOut } = queryParams;
  const modalRef = useRef();

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  return (
    <div css={modalContainer}>
      <div>
        <h4 className="title">{destination?.map((el) => el?.address?.city)[0]}</h4>
        {checkIn && checkOut && (
          <p className="date">{`${moment(checkIn).format(DISPLAY_DATE_FORMAT)} - ${moment(checkOut).format(
            DISPLAY_DATE_FORMAT,
          )}`}</p>
        )}
      </div>
      <div className="badges">
        {starsBadgeInfo(getText)?.map((el, ind) => (
          <ColorBatch
            key={ind}
            withoutStartCase
            type="default"
            className="stars-badge"
            onClick={() => onSelectHotelStars(el.value)}>
            {el.label}
          </ColorBatch>
        ))}
      </div>

      <div className="colors-container">
        <div>
          <div className="green" />
          <h5 className="right">{convertedToLocalCurrencyPrice(starsData[selectedHotelStars].minPrice)}</h5>
        </div>
        <div>
          <Tooltip
            placement="top"
            css={tooltipContainer}
            contentClassName={tooltipContent}
            content={<h5>{convertedToLocalCurrencyPrice(starsData[selectedHotelStars].avgPrice)}</h5>}>
            <div className="yellow" />
          </Tooltip>
          <h5 className="none">{convertedToLocalCurrencyPrice(starsData[selectedHotelStars].maxPrice)}</h5>
        </div>
        <div>
          <div className="red" />
          <h5 className="left">{convertedToLocalCurrencyPrice(starsData[selectedHotelStars].maxPrice)}</h5>
        </div>
      </div>
      <h5 className="text">
        <span>{getText('avgStarsRetailHotelPrice', { stars: selectedHotelStars })} </span>
        {convertedToLocalCurrencyPrice(starsData[selectedHotelStars].avgPrice)}
        <span> {getText('perNightForTheseDates')}</span>
      </h5>
    </div>
  );
});

PriceStarsTooltipContent.propTypes = {
  destination: PropTypes.array,
  starsData: PropTypes.object,
  onSelectHotelStars: PropTypes.func,
  selectedHotelStars: PropTypes.number,
};

export default PriceStarsTooltipContent;
