import { differenceBy, intersectionBy, isEmpty, isNil, isNumber, isString } from 'lodash-es';
import queryString from 'query-string';
import { HIGHLIGHTED_HOTEL_AMENITIES } from 'enums';

export const getAllHotelsParams = (options = {}) => {
  const {
    checkIn,
    checkOut,
    city,
    country,
    latitude,
    longitude,
    adults,
    children,
    childrenAges,
    rooms,
    propertyThemes,
    rateOptions,
    reviewRatings,
    minPrice,
    maxPrice,
    bedsCount,
    neighborhoods,
    brands,
    starRatings,
    amenities,
    propertyTypes,
    language,
    currencyCode,
    sortBy,
    pageIndex,
    pageSize,
    searchId,
    radius,
    unit,
  } = options;

  return queryString.stringify(
    {
      ...(checkIn && { checkIn }),
      ...(checkOut && { checkOut }),
      ...(isNumber(latitude) && { latitude }),
      ...(isNumber(longitude) && { longitude }),
      ...(isString(city) && { city }),
      ...(isString(country) && { country }),
      ...(isNumber(adults) && { adults }),
      ...(isNumber(children) && { children }),
      ...(childrenAges && { childrenAges }),
      ...(isNumber(rooms) && { rooms }),
      ...(propertyThemes && { propertyThemes }),
      ...(rateOptions && { rateOptions }),
      ...(reviewRatings && { reviewRatings }),
      ...(!isNil(minPrice) && { minPrice }),
      ...(!isNil(maxPrice) && { maxPrice }),
      ...(bedsCount && { bedsCount }),
      ...(neighborhoods && { neighborhoods }),
      ...(brands && { brands }),
      ...(starRatings && { starRatings }),
      ...(amenities && { amenities }),
      ...(propertyTypes && { propertyTypes }),
      ...(isString(language) && { language }),
      ...(isString(currencyCode) && { currencyCode }),
      ...(!isNil(sortBy) && { sortBy }),
      ...(isNumber(pageIndex) && { pageIndex }),
      ...(isNumber(pageSize) && { pageSize }),
      ...(isNumber(searchId) && { searchId }),
      ...(isNumber(radius) && { radius }),
      ...(isString(unit) && { unit }),
    },
    { arrayFormat: 'comma' },
  );
};

export const hotelDetailsByIdParams = (options = {}) => {
  const { searchId, provider } = options;

  return queryString.stringify(
    { ...((!isEmpty(searchId) || isString(searchId)) && { searchId }), ...(isString(provider) && { provider }) },
    { arrayFormat: 'comma' },
  );
};

export const hotelDetailsByIdModel = (response) => {
  const hotelAmenitiesData = response?.amenities?.flatMap((el) =>
    el?.name?.toLowerCase()?.includes('<br />')
      ? el.name
          ?.toLowerCase()
          ?.split('<br />')
          ?.map((elem) => ({ ...el, name: elem.toLowerCase() }))
      : el?.name?.toLowerCase()?.includes(' ,')
      ? el.name
          ?.toLowerCase()
          ?.split(' ,')
          ?.map((elem) => ({ ...el, name: elem.toLowerCase() }))
      : { ...el, name: el.name.toLowerCase() },
  );

  const hotelAmenities = differenceBy(hotelAmenitiesData, HIGHLIGHTED_HOTEL_AMENITIES, 'name');
  const highlightedAmenities = intersectionBy(HIGHLIGHTED_HOTEL_AMENITIES, hotelAmenitiesData, 'name');

  return {
    ...response,
    highlightedAmenities,
    hotelAmenities,
  };
};

export const hotelRoomRatesParams = (options = {}) => {
  const { searchId, pageIndex, pageSize } = options;

  return queryString.stringify(
    {
      ...((!isEmpty(searchId) || isString(searchId)) && { searchId }),
      ...((!isEmpty(pageIndex) || isNumber(pageIndex)) && { pageIndex }),
      ...((!isEmpty(pageSize) || isNumber(pageSize)) && { pageSize }),
    },
    { arrayFormat: 'index' },
  );
};

export const getMyBookingsParams = (options = {}) => {
  const { status, pageIndex, pageSize } = options;

  return queryString.stringify(
    {
      ...(isString(status) && { status }),
      ...((!isEmpty(pageIndex) || isNumber(pageIndex)) && { pageIndex }),
      ...((!isEmpty(pageSize) || isNumber(pageSize)) && { pageSize }),
    },
    { arrayFormat: 'index' },
  );
};

export const bookingDetailsByIdModel = (response) => {
  const hotelAmenitiesData = response?.amenities?.flatMap((el) =>
    el?.name?.toLowerCase()?.includes('<br />')
      ? el?.name
          ?.toLowerCase()
          ?.split('<br />')
          ?.map((elem) => ({ ...el, name: elem?.toLowerCase() }))
      : { ...el, name: el?.name?.toLowerCase() },
  );

  const hotelAmenities = differenceBy(hotelAmenitiesData, HIGHLIGHTED_HOTEL_AMENITIES, 'name');

  const address = {
    ...response?.address,
    address: response?.address?.address?.replaceAll('\t', ', '),
  };

  return {
    ...response,
    hotelAmenities,
    address,
    roomDetails: !isNil(response?.roomDetails)
      ? {
          ...response?.roomDetails,
          boardType: response?.roomDetails?.boardType?.replace('|t|', ', '),
          cancellationPolicy: response?.roomDetails?.cancellationPolicy?.replace('|t|', ' '),
          maxOccupancy: response?.roomDetails?.maxOccupancy?.replace('|t|', ' - '),
        }
      : null,
  };
};
