import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import { isEmpty, isNil, omit } from 'lodash-es';
import { Button, Col, DateRangePicker, Icon, Row, SearchBar, VisitorsInput, useUrlParams } from 'components';
import { ROUTES } from 'enums';
import { getLocations } from 'services';
import { homeSearchContainer } from './styles';

const DISPLAY_FORMAT = 'D MMM YYYY';

const HomeFilterBar = () => {
  const { queryParams, clearQueryParams } = useUrlParams();
  const history = useHistory();
  const { checkIn, checkOut, location, locationName, adults, children, rooms, childrenAges } = queryParams;
  const [params, setParams] = useState({});

  const isDisabledButton =
    (isEmpty(params?.checkIn) && isEmpty(checkIn) && isEmpty(params?.checkOut) && isEmpty(checkOut)) ||
    (isNil(params?.location?.display_name) && isNil(locationName)) ||
    (isNil(params?.rooms) &&
      isNil(rooms) &&
      isNil(params?.children) &&
      isNil(children) &&
      isNil(params?.adults) &&
      isNil(adults));

  const handleDelete = (options) => {
    options.inputProps.onDelete();
    clearQueryParams(['locationName', 'location']);
  };

  const handleSearchChange = (value) =>
    setParams((prev) => ({
      ...prev,
      location: value,
      locationName: value.display_name,
      latitude: value.lat,
      longitude: value.lon,
      city: value.city === '' ? value.name : value.city,
      country: value.country,
    }));

  const handleDateChange = (newParams) => {
    const checkInData = moment(newParams.startDate).format('MM-D-YYYY');
    const checkOutData = moment(newParams.endDate).format('MM-D-YYYY');
    const isNewDates = checkInData !== checkIn || checkOutData !== checkOut;

    isNewDates && setParams((prev) => ({ ...prev, checkIn: checkInData, checkOut: checkOutData }));
  };

  const handleVisitorsChange = (value) => {
    const isChanged =
      value.adults !== queryParams.adults ||
      value.children !== queryParams.children ||
      value.rooms !== queryParams.rooms ||
      value.childrenAges !== queryParams.childrenAges;

    isChanged &&
      setParams((prev) => ({
        ...prev,
        adults: value.adults,
        children: value.children,
        rooms: value.rooms,
        childrenAges: value.childrenAges,
      }));
  };

  return (
    <Row gap={8} css={homeSearchContainer}>
      <Col md={12} lg={4}>
        <SearchBar
          className="search-bar"
          placeholder="Where are you going?"
          displayKey={'display_name'}
          value={locationName ?? location?.display_name ?? params?.location?.display_name}
          getOptions={(input) => getLocations({ name: input })}
          renderSuggestion={(val) => (
            <div
              className="suggestion"
              {...val.itemProps}
              role={val.itemProps.role}
              onClick={() => {
                handleSearchChange(val.item);
                val.suggestionItemProps.onClick(val.item);
              }}>
              <div className="icon-container">
                <Icon material iconName="location_on" size={16} color="black" />
              </div>
              {val.item.display_name}
            </div>
          )}
          renderInput={(val) => (
            <div {...val.containerProps}>
              <label htmlFor={val.inputProps.id}>
                <Icon {...val.iconProps} />
              </label>
              <input {...omit(val.inputProps, 'onFocus', 'onDelete')} />
              <label htmlFor={val.inputProps.id}>
                <Icon material size={20} iconName="close" css={val.iconProps.css} onClick={() => handleDelete(val)} />
              </label>
            </div>
          )}
          onInputChange={handleSearchChange}
        />
      </Col>
      <Col sm={12} md={6} lg={3}>
        <DateRangePicker
          showClear={false}
          noFuture={false}
          withCalendarIcon
          startDate={checkIn}
          endDate={checkOut}
          onChange={handleDateChange}
          minDate={moment()}
          displayFormat={DISPLAY_FORMAT}
          className="date-range-picker"
        />
      </Col>
      <Col sm={12} md={6} lg={4} className="visitors-container">
        <VisitorsInput
          values={{
            adults: params?.adults ? params?.adults : adults,
            children: params?.children ? params?.children : children,
            rooms: params?.rooms ? params?.rooms : rooms,
            childrenAges: params?.childrenAges ? params.childrenAges : childrenAges,
          }}
          onChange={handleVisitorsChange}
          tooltipClassName="tooltip"
          className="edit-container"
        />
      </Col>
      <Col sm={12} md={6} lg={1} displayFlex justify="center">
        <Button
          small
          onClick={() =>
            history.push({ pathname: ROUTES.HotelsListing, search: qs.stringify(params, { arrayFormat: 'comma' }) })
          }
          disabled={isDisabledButton}>
          <Icon material iconName="search" />
        </Button>
      </Col>
    </Row>
  );
};

export default HomeFilterBar;
