import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { add, mul } from 'exact-math';
import { capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Col,
  ColorBatch,
  Icon,
  Image,
  PriceLabel,
  Row,
  RatingReviewStars,
  showApiError,
  useUrlParams,
} from 'components';
import { HOTEL_ENTERTAINMENT_MAP, DEFAULT_CURRENCY } from 'enums';
import { getConfig } from 'services';
import { cardContainer } from './styles';

const MIN_EMPTY_ROOMS = 5;

const HotelCart = (props) => {
  const { hotelInfo, searchId, queryParams } = props;
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const history = useHistory();
  const { setQueryParams } = useUrlParams();
  const [config, setConfig] = useState({});

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    const [res, err] = await getConfig('CashForCoinExchangeRate');
    err ? showApiError(err) : setConfig(res ? JSON.parse(res.value) : false);
  };

  const handleChangePage = () => {
    history.push({
      pathname: `/hotels/${hotelInfo.id}/overview`,
      state: { ...hotelInfo, ...queryParams, searchId },
    });
    setQueryParams({ ...queryParams, searchId });
  };

  return (
    <div role="button" tabIndex={0} onClick={handleChangePage}>
      <Row css={cardContainer(hotelInfo?.priceDetails?.cryptoPrice > 0)}>
        <Col xs={12} sm={6} md={9} lg={9} className="hotel-info" displayFlex direction="row">
          <Image src={hotelInfo?.image} className="hotel-image" />
          <div className="info">
            <div>
              {hotelInfo?.propertyThemes && (
                <div>
                  {hotelInfo?.propertyThemes?.slice(0, 2)?.map((el, ind) => (
                    <ColorBatch
                      key={ind}
                      withoutStartCase
                      type={HOTEL_ENTERTAINMENT_MAP[el?.id]?.color}
                      className="entertainment-badge">
                      <div className="badge-container">
                        <Icon size={20} material iconName={HOTEL_ENTERTAINMENT_MAP[el?.id]?.icon} margin="0 4px 0 0" />{' '}
                        <p> {getText(HOTEL_ENTERTAINMENT_MAP[el?.id]?.text)}</p>
                      </div>
                    </ColorBatch>
                  ))}
                </div>
              )}
              {hotelInfo?.name && <h4>{hotelInfo?.name}</h4>}
              <div className="location">
                {hotelInfo?.address?.address && <span>{`${capitalize(hotelInfo?.address?.address)}`}</span>}
                {hotelInfo?.address?.cityName && <span>{`, ${capitalize(hotelInfo?.address?.cityName)}`}</span>}
                {hotelInfo?.address?.countryName && <span>{`, ${capitalize(hotelInfo?.address?.countryName)}`}</span>}
              </div>
            </div>
            <div>
              {hotelInfo?.amenities?.length > 0 && (
                <div className="amenities">
                  {hotelInfo?.amenities?.slice(0, 2)?.map((el, ind) => (
                    <div key={ind} className="amenities">
                      <Icon material iconName="check_small" />
                      <p className="amenities-info">{el?.name}</p>
                    </div>
                  ))}
                </div>
              )}
              <RatingReviewStars hotel={hotelInfo} />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className="price-info">
          <div className="column">
            {hotelInfo?.priceDetails?.savingPercentage > 0 && (
              <ColorBatch withoutStartCase type="error">
                {hotelInfo?.priceDetails?.savingPercentage}% {getText('off')}
              </ColorBatch>
            )}
            {hotelInfo?.roomLeft > 0 && hotelInfo?.roomLeft <= MIN_EMPTY_ROOMS && (
              <ColorBatch withoutStartCase type="error">
                {getText('onlyFewLeftAt')}
              </ColorBatch>
            )}
            <div className="price">
              <p className="original-price">
                <PriceLabel
                  fiat={Number(
                    add(
                      hotelInfo?.priceDetails?.cryptoPrice ? mul(hotelInfo?.priceDetails?.cryptoPrice, config) : 0,
                      add(hotelInfo?.priceDetails?.price ?? 0, hotelInfo?.priceDetails?.savingsAmount ?? 0),
                    ),
                  )}
                  fiatOriginCurrency={DEFAULT_CURRENCY.code}
                  fiatDesiredCurrency={selectedCurrency?.code}
                  fiatOnly={false}
                />
              </p>

              {hotelInfo?.priceDetails?.price > 0 && (
                <h4 className="discounted-price">
                  <PriceLabel
                    fiat={Number(hotelInfo?.priceDetails?.price)}
                    crypto={hotelInfo?.priceDetails?.cryptoPrice}
                    fiatOriginCurrency={DEFAULT_CURRENCY.code}
                    fiatDesiredCurrency={selectedCurrency?.code}
                    fiatOnly={false}
                  />
                </h4>
              )}
            </div>
            <p className="for-night">{getText('total')}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

HotelCart.propTypes = {
  hotelInfo: PropTypes.object,
  searchId: PropTypes.string,
  queryParams: PropTypes.object,
};

export default HotelCart;
