import { useRef, useState } from 'react';
import { isNil, toUpper } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { showApiError, showError, showSuccess, Spinner, Stepper, useLeavePage, LeavingModal } from 'components';
import { ROUTES, WALLET_TYPE } from 'enums';
import { useUserStore } from 'stores';
import { createHotelBookings } from 'services';
import Payment from './Payment';
import Review from './Review';
import { checkoutContainer } from './styles';

const HotelCheckout = ({ history, location }) => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const [dataGuests, setDataGuests] = useState({});
  const [allWallets, setAllWallets] = useState([]);
  const [hasError, setHasError] = useState({ senderAccountId: false, paymentMethod: false, priceError: false });
  const [values, setValues] = useState({});
  const stepperRef = useRef();
  const leavingRef = useRef();
  const loaderRef = useRef();
  const badges = ['secureTransactions', '24HourService', 'trustedPayments'];
  const roomDetails = history.location?.state?.room ?? location?.state?.goBackData?.roomDetails;
  const hotelDetails = history.location?.state?.hotelDetails ?? location?.state?.goBackData?.hotelDetails;
  const filters = history.location?.state?.filters ?? location?.state?.goBackData?.filters;
  const searchId = filters?.searchId ?? location?.state?.goBackData?.searchId;
  const wallets =
    allWallets &&
    allWallets?.filter(
      (el) =>
        el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase() ||
        el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase(),
    );

  const handleCompleteReview = (val) => {
    setDataGuests((prev) => ({ ...prev, val }));
    stepperRef.current?.changeActive(2);
  };

  const handleError = (error) => {
    error.response.status === 424 || error.response.data.title === 'Failed Dependency'
      ? showError(getText('thisAccountIsNotMigrated'))
      : showApiError(error);

    setTimeout(() => {
      loaderRef.current.hideLoading();
    }, 3600);
  };

  const handleSuccess = () => {
    setTimeout(() => {
      loaderRef.current.hideLoading();
      showSuccess(getText('successfullyCreateBooking'));
      history.push(ROUTES.SuccessCheckout);
    }, 1000);
  };

  const handleCompletedPayment = async (val) => {
    if (isNil(values?.senderAccountId) && isNil(values?.paymentMethod) && hasError?.priceError) {
      handleScrollToError('#accountDropdown');
      return setHasError((prev) => ({ ...prev, senderAccountId: true, paymentMethod: true, priceError: true }));
    }

    if (isNil(values?.senderAccountId) && isNil(values?.paymentMethod)) {
      handleScrollToError('#accountDropdown');
      return setHasError((prev) => ({ ...prev, senderAccountId: true, paymentMethod: true }));
    }

    if (isNil(values?.senderAccountId) && hasError?.priceError) {
      handleScrollToError('#accountDropdown');
      return setHasError((prev) => ({ ...prev, senderAccountId: true, priceError: true }));
    }

    if (isNil(values?.paymentMethod) && hasError?.priceError) {
      handleScrollToError('#paymentMethod');
      return setHasError((prev) => ({ ...prev, paymentMethod: true, priceError: true }));
    }

    if (isNil(values?.senderAccountId)) {
      handleScrollToError('#accountDropdown');
      return setHasError((prev) => ({ ...prev, senderAccountId: true }));
    }

    if (isNil(values?.paymentMethod)) {
      handleScrollToError('#paymentMethod');
      return setHasError((prev) => ({ ...prev, paymentMethod: true }));
    }

    if (hasError?.priceError) {
      handleScrollToError('#priceError');
      return setHasError((prev) => ({ ...prev, priceError: true }));
    }

    const requestData = {
      searchId,
      senderAccountId: val?.senderAccountId,
      paymentMethodName: val?.paymentMethod?.name,
      customerFirstName: user?.userInfo?.firstName,
      customerLastName: user?.userInfo?.lastName,
      customerEmail: user?.userInfo?.email,
      customerPhoneNumber: user?.userInfo?.phone,
      rateBaseId: roomDetails.roomRateId,
      guests: dataGuests?.val.guests,
    };

    handleScrollToTop();
    loaderRef.current.showLoading();

    const [, err] = await createHotelBookings(requestData);
    if (err) return handleError(err);
    handleSuccess();
    setTimeout(() => {}, 5000);
  };

  const handleBack = () => stepperRef.current?.changeActive(stepperRef.current?.currentStep - 1);

  const handleScrollToTop = () =>
    document.getElementById('#header').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  const handleScrollToError = (containerId) =>
    containerId &&
    document.getElementById(containerId)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  const stepsDefinition = [
    {
      id: 1,
      label: getText('review'),
      component: (
        <Review
          roomDetails={roomDetails}
          hotelDetails={hotelDetails}
          filters={filters}
          formData={values?.guests}
          onCompleted={handleCompleteReview}
          onScrollToTop={handleScrollToTop}
          onSetGuests={setValues}
        />
      ),
    },
    {
      id: 2,
      label: getText('payment'),
      component: (
        <Payment
          hasError={hasError}
          paymentData={{ itemPrice: roomDetails?.priceDetails?.price, wallets, values }}
          roomDetails={roomDetails}
          filters={filters}
          onBack={handleBack}
          onScrollToTop={handleScrollToTop}
          onSetHasError={setHasError}
          onSetAccountAndPaymentMethod={setValues}
          onScrollToError={handleScrollToError}
          onSetWallets={setAllWallets}
          onCompleted={handleCompletedPayment}
        />
      ),
    },
  ];

  const leavePage =
    history.location.pathname === `/hotels/${hotelDetails?.hotelId}/checkout` &&
    stepsDefinition?.length === stepperRef.current?.currentStep;

  const { setTriggerExit } = useLeavePage(history, leavePage, leavingRef, {
    ...filters,
    goBackPath: `/hotels/${hotelDetails?.hotelId}/checkout`,
    hotelDetails,
    roomDetails,
    filters,
    formData: values?.guests ?? dataGuests?.val?.guests,
    searchId,
  });

  return (
    <div css={checkoutContainer}>
      <div className="batch">
        {badges.map((el, ind) => (
          <p key={ind}>{toUpper(getText(el))}</p>
        ))}
      </div>
      <p className="secure-batch">{getText('secureCheckout')}</p>

      <Stepper ref={stepperRef} steps={stepsDefinition} className="stepper" />

      {leavePage && (
        <LeavingModal
          ref={leavingRef}
          hotelId={hotelDetails?.hotelId}
          onLeave={() => {
            setTriggerExit((obj) => ({ ...obj, onOk: true }));
            leavingRef.current?.close();
          }}
          onStay={() => leavingRef.current?.close()}
        />
      )}
      <Spinner ref={loaderRef} text={getText('buyingHotelRooms')} />
    </div>
  );
};

export default HotelCheckout;
