import { useHistory } from 'react-router-dom';
import { sub } from 'exact-math';
import { startCase } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { PriceLabel } from 'components';
import { WALLET_TYPE, DEFAULT_CURRENCY } from 'enums';
import { ReactComponent as WalletIcon } from 'assets/images/wallet-icon.svg';
import { walletCartContainer } from './styles';

const WalletCart = ({ wallet, linkTo, className }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const history = useHistory();

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  const walletsMap = (walletType, balance) => {
    switch (true) {
      case walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase():
        return {
          label: 'crypto',
          currency: 'crypto',
          balance: balance?.toFixed(4),
        };
      case walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase():
        return {
          label: 'fiat',
          currency: null,
          balance: convertedToLocalCurrencyPrice(balance),
        };
      default:
        return null;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => linkTo && history.push(linkTo)}
      css={walletCartContainer(wallet?.walletType.toLowerCase())}
      {...(className && { className })}>
      <WalletIcon className="icon" />
      <div>
        <p className="wallet-title">{getText(`${walletsMap(wallet?.walletType.toLowerCase())?.label}Wallet`)}</p>
        <h5>
          {
            walletsMap(wallet?.walletType.toLowerCase(), sub(wallet?.balance ?? 0, wallet?.blockedBalance ?? 0))
              ?.balance
          }
          <span>{` ${startCase(
            getText(
              walletsMap(wallet?.walletType.toLowerCase(), sub(wallet?.balance ?? 0, wallet?.blockedBalance ?? 0))
                ?.currency,
            ),
          )}`}</span>
        </h5>
      </div>
    </div>
  );
};

export default WalletCart;
