import { useRef } from 'react';
import { capitalize, isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Carousel, Col, Icon, Row, AmenitiesModal } from 'components';
import { bookingRoomCardContainer } from './styles';

const BookingRoomCart = (props) => {
  const { bookingInfo, booking } = props;
  const { getText } = useTranslations();
  const modalRef = useRef();

  return (
    <>
      <div css={bookingRoomCardContainer}>
        <Row className="room-container" gap={8}>
          <Col xs={12} sm={12} md={6} lg={5}>
            {!isNil(bookingInfo?.images) && (
              <Carousel
                images={bookingInfo?.images?.length ? bookingInfo?.images : booking?.hotelImages}
                className="carousel-styles"
              />
            )}
          </Col>

          <Col xs={12} sm={12} md={6} lg={7}>
            <div className="room-info">
              <div>
                {bookingInfo?.roomType !== '' &&
                  !isNil(bookingInfo?.roomType) &&
                  bookingInfo?.fareType !== '' &&
                  !isNil(bookingInfo?.fareType) && (
                    <h5 className="room-title">
                      {`${bookingInfo?.roomType}`}{' '}
                      {bookingInfo?.fareType !== '' &&
                        !isNil(bookingInfo?.fareType) &&
                        `(${capitalize(getText(bookingInfo?.fareType))})`}
                    </h5>
                  )}

                {bookingInfo?.roomType !== bookingInfo?.description &&
                  bookingInfo?.roomType !== '' &&
                  !isNil(bookingInfo?.roomType) &&
                  bookingInfo?.description !== '' &&
                  !isNil(bookingInfo?.description) && (
                    <p className="room-board-type"> ${capitalize(getText(bookingInfo?.description))}</p>
                  )}

                {bookingInfo?.roomRateId !== '' && !isNil(bookingInfo?.roomRateId) && (
                  <p className="room-board-type">{`# ${capitalize(bookingInfo?.roomRateId)}`}</p>
                )}

                {bookingInfo?.boardType !== '' && !isNil(bookingInfo?.boardType) && (
                  <p className="room-board-type">({capitalize(bookingInfo?.boardType)})</p>
                )}

                {((bookingInfo?.squareFootage > 0 && !isNil(bookingInfo?.squareFootage)) ||
                  (bookingInfo?.maxOccupancy !== '' && !isNil(bookingInfo?.maxOccupancy))) && (
                  <div className="square-footage">
                    {bookingInfo?.squareFootage > 0 && !isNil(bookingInfo?.squareFootage) && (
                      <div>
                        <Icon material iconName="fullscreen" color="gray300" className="icon-room" />
                        <p>{getText('squareFootage', { count: bookingInfo?.squareFootage })}</p>
                      </div>
                    )}

                    {!isNil(bookingInfo?.maxOccupancy) && bookingInfo?.maxOccupancy !== '' && (
                      <>
                        <div className="dot" />
                        <p>{getText('sleepsUpTo', { count: bookingInfo?.maxOccupancy })}</p>
                      </>
                    )}
                  </div>
                )}
              </div>

              {!!bookingInfo?.amenities?.length && (
                <Button small type="link" onClick={() => modalRef.current?.open()} className="button">
                  {getText('amenities')}
                  <Icon material iconName="keyboard_arrow_right" className="icon" />
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {!isNil(bookingInfo?.cancellationPolicy) && (
          <div className="refund-container">
            <h4 className="refund-title">{getText('cancellationPolicy')}</h4>
            <p className="refund-desc">{bookingInfo?.cancellationPolicy}</p>
          </div>
        )}

        <Row gap={16}>
          {!isNil(bookingInfo?.guests) && bookingInfo?.guests > 0 && (
            <Col xs={12} sm={6} md={6} lg={6}>
              <h5>{getText('adults')}</h5>
              {bookingInfo?.guests?.adults?.length ? (
                bookingInfo?.guests?.adults?.map((el, ind) => (
                  <p className="guests-rooms-styles" key={ind}>
                    {`${el?.firstName} ${el?.lastName}`}
                  </p>
                ))
              ) : (
                <p className="guests-rooms-styles">N/A</p>
              )}
              {!!bookingInfo?.guests?.childs?.length && <h5>{getText('childs')}</h5>}
              {!!bookingInfo?.guests?.childs?.length &&
                bookingInfo?.guests?.childs?.map((el, ind) => (
                  <p className="guests-rooms-styles" key={ind}>
                    {`${el?.firstName} ${el?.lastName}`}
                  </p>
                ))}
            </Col>
          )}

          {!isNil(bookingInfo?.rooms) && (
            <Col xs={12} sm={3} md={3} lg={3}>
              <h5>{getText('rooms')}</h5>
              <p className="guests-rooms-styles">{`${bookingInfo?.rooms} ${getText('rooms')?.toLowerCase()}`}</p>
            </Col>
          )}

          {!isNil(booking?.nights) && (
            <Col xs={12} sm={3} md={3} lg={3}>
              <h5>{getText('nights')}</h5>
              <p className="guests-rooms-styles">{`${booking?.nights} ${getText('nights')?.toLowerCase()}`}</p>
            </Col>
          )}
        </Row>
      </div>

      <AmenitiesModal ref={modalRef} amenities={bookingInfo?.amenities} />
    </>
  );
};

BookingRoomCart.propTypes = {
  bookingInfo: PropTypes.object,
  booking: PropTypes.object,
};

export default BookingRoomCart;
